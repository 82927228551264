import { Box, Heading, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';
import { redirect } from 'next/navigation';
import router, { useRouter } from 'next/router';





const Home = () => {
  // const { push } = useRouter();

  // useEffect(() => {
  //    push('/txs');
  // }, []);
  return (
   <p>Request error. Refresh page</p>
  );
};

export default Home;
